/** PAGE HOME */
.ibox-content {
  position: relative;
}

/** SPINNER  */
.js_spinner-container {
  z-index: 100;
  width: 100%;
  height: 100%;
  opacity: .6;
  position: absolute;
  background: #f2f2f2;
  display: block;
  left: 0;
  top: 0;
}

.js_spinner-container.hidden {
  display: none;
}

/** CHARTS */
//hide download option for ie8
.ie8 div.highcharts-menu-item ~ .highcharts-menu-item,
.ie8 .highcharts-menu hr {
  display: none;
}

/** TABLES  */
.table-responsive {
  height: 500px;
}

.scrollable {
  overflow-y: scroll !important;
}

.no-data {
  font-size: 12px;
  font-weight: bold;
  color: #666666;
  fill: #666666;
}