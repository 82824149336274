/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.2
 *
*/

// Google Fonts
@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");

// Variables, Mixins
@import "variables";
@import "mixins";

// INSPINIA Theme Elements
@import "typography";
@import "navigation";
@import "top_navigation";
@import "buttons";
@import "badgets_labels";
@import "elements";
@import "sidebar";
@import "base";
@import "pages";
@import "chat";
@import "metismenu";
@import "spinners";
@import "custom";

// Landing page
@import "landing";

// RTL Support
@import "rtl";

// For demo only - config box style
@import "theme-config";

// INSPINIA Skins
@import "skins";

// Media query style
@import "media";

@import "awesome_bootstrap_checkbox.scss";

@import "mindtools-override";