.metismenu .nav-header {
  background-color: lightgrey;
}

.dropdown-toggle {
  .font-bold {
    color: $text-color;
  }
}

.help-block strong {
  color: red;
}

.ibox-content .pagination {
  margin: 0;
  margin-bottom: 10px;
}