#page-wrapper {
  padding: 0 !important;
}

.center-text {
  text-align: center;
}

.white-bg {
  background: #ffffff !important;
}

.full-height-child {
  height: 473px !important;
}

.td-v-center {
  vertical-align: middle !important;
}

.content-height {
  height: 500px;
}

#user-segment-chart-container,
#resource-type-chart-container,
#skill-area-chart-container,
#chart-user-segmentation-views-container {
  height: 436px;
}

.breadcrumb li {
  margin-right: 4px;
}

.logo-header {
  height: 50px;
  margin: 25px 0px;
}

.col-3 {
  width: 25%;
  display: block;
  float: left;
}

.p-a-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.m-t-1 {
  margin-top: 10px;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-a-0 {
  margin: 0 !important;
}

.m-b-1 {
  margin-bottom: 10px;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-t-1 {
  margin-top: 12.5px;
}

.m-t-2 {
  margin-top: 25px;
}

.m-t-6 {
  margin-top: 75px;
}

.m-t-12 {
  margin-top: 125px;
}

.b-l-0-2 {
  border-left: solid 2px #f3f3f4;
}

.b-r-0-2 {
  border-right: solid 2px #f3f3f4;
}

.b-t-0-2 {
  border-top: solid 2px #f3f3f4;
}

.b-b-0-2 {
  border-bottom: solid 2px #f3f3f4;
}

.font-lrg {
  font-size: 2.19em;
}

.block-center {
  display: block;
  margin: 0 auto;
  width: 98%;
  text-align: center;
}

.ibox-title {
  overflow: hidden;
}

.text-danger {
  color: #ed5565 !important;
}

.block-center.text-danger {
  width: 98.5%;
}

.text-navy {
  color: #8DC63F !important;
}

.block-center.text-navy {
  width: 99%;
}

.widget.btn-lg {
  border-radius: 0;
}