@font-face {
  font-family: 'MindToolsIcons';
  font-weight: normal;
  src: url("assets/webfonts/MindToolsIcons.eot");
  src: url("/assets/webfonts/MindToolsIcons.eot?#iefix") format("embedded-opentype"), url("/assets/webfonts/MindToolsIcons.woff2") format("woff2"), url("/assets/webfonts/MindToolsIcons.woff") format("woff");
}

[class^="mt-"], [class*=" mt-"] {
  font-family: 'MindToolsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /*line-height: 1;*/

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mt-pencil6:before {
  content: "\e913";
}

.mt-reading:before {
  content: "\e997";
}

.mt-library:before {
  content: "\e998";
}

.mt-compass5:before {
  content: "\ea43";
}

.mt-grid5:before {
  content: "\ec5e";
}

.mt-spotlight2:before {
  content: "\ecb8";
}

.mt-facebook2:before {
  content: "\eee8";
}

.mt-facebook3:before {
  content: "\eeea";
}

.mt-join:before {
  content: "\e64f";
}

.mt-newsletter:before {
  content: "\e650";
}

.mt-rss-square:before {
  content: "\e645";
}

.mt-rss-round:before {
  content: "\e646";
}

.mt-rss-triangle:before {
  content: "\e647";
}

.mt-linkedin:before {
  content: "\e648";
}

.mt-linkedin-square:before {
  content: "\e649";
}

.mt-twitter1:before {
  content: "\e64a";
}

.mt-twitter2:before {
  content: "\e64b";
}

.mt-twitter:before {
  content: "\e64c";
}

.mt-facebook-round:before {
  content: "\e64d";
}

.mt-facebook-letter:before {
  content: "\e64e";
}

.mt-facebook:before {
  content: "\e644";
}

.mt-reading2:before {
  content: "\e62f";
}

.mt-spotlight:before {
  content: "\e630";
}

.mt-compass:before {
  content: "\e631";
}

.mt-cube:before {
  content: "\e632";
}

.mt-pencil:before {
  content: "\e633";
}

.mt-dots:before {
  content: "\e634";
}

.mt-grid-3:before {
  content: "\e635";
}

.mt-meter-fast:before {
  content: "\e636";
}

.mt-grid-5:before {
  content: "\e637";
}

.mt-eye:before {
  content: "\e638";
}

.mt-stack-text:before {
  content: "\e639";
}

.mt-cabinet:before {
  content: "\e63a";
}

.mt-podium:before {
  content: "\e63b";
}

.mt-archery:before {
  content: "\e63c";
}

.mt-puzzle:before {
  content: "\e63d";
}

.mt-direction:before {
  content: "\e63e";
}

.mt-books:before {
  content: "\e63f";
}

.mt-book-1:before {
  content: "\e640";
}

.mt-sphere:before {
  content: "\e641";
}

.mt-library2:before {
  content: "\e642";
}

.mt-users-4:before {
  content: "\e643";
}

.mt-feedback:before {
  content: "\e600";
}

.mt-arrow-right:before {
  content: "\e62c";
}

.mt-arrow-left:before {
  content: "\e62d";
}

.mt-heart:before {
  content: "\e62b";
}

.mt-tick-nocircle:before {
  content: "\e61f";
}

.mt-x:before {
  content: "\e620";
}

.mt-tick:before {
  content: "\e621";
}

.mt-minus:before {
  content: "\e622";
}

.mt-plus:before {
  content: "\e623";
}

.mt-question:before {
  content: "\e624";
}

.mt-play:before {
  content: "\e625";
}

.mt-share:before {
  content: "\e626";
}

.mt-printer:before {
  content: "\e627";
}

.mt-download:before {
  content: "\e628";
}

.mt-quotes:before {
  content: "\e629";
}

.mt-book:before {
  content: "\e62a";
}

.mt-message:before {
  content: "\e610";
}

.mt-your-business:before {
  content: "\e601";
}

.mt-video:before {
  content: "\e602";
}

.mt-toolkit:before {
  content: "\e603";
}

.mt-time-management:before {
  content: "\e604";
}

.mt-team-management:before {
  content: "\e605";
}

.mt-stress:before {
  content: "\e606";
}

.mt-strategy-tools:before {
  content: "\e607";
}

.mt-start-here:before {
  content: "\e608";
}

.mt-star:before {
  content: "\e609";
}

.mt-speech-bubble:before {
  content: "\e60a";
}

.mt-search:before {
  content: "\e60b";
}

.mt-project-management:before {
  content: "\e60c";
}

.mt-profile:before {
  content: "\e60d";
}

.mt-problem-solving:before {
  content: "\e60e";
}

.mt-plp:before {
  content: "\e60f";
}

.mt-new-members:before {
  content: "\e611";
}

.mt-menu:before {
  content: "\e612";
}

.mt-lightbulb:before {
  content: "\e613";
}

.mt-learning-sklills:before {
  content: "\e614";
}

.mt-leadership-skills:before {
  content: "\e615";
}

.mt-decision-making:before {
  content: "\e616";
}

.mt-communication-skills:before {
  content: "\e617";
}

.mt-clipboard:before {
  content: "\e618";
}

.mt-chart:before {
  content: "\e619";
}

.mt-career-skills:before {
  content: "\e61a";
}

.mt-calendar:before {
  content: "\e61b";
}

.mt-bookmark:before {
  content: "\e61c";
}

.mt-audio:before {
  content: "\e61d";
}

.mt-arrow-up:before {
  content: "\e61e";
}

.mt-arrow-down:before {
  content: "\e62e";
}