/** BASE **/
body {
  font-size: 16px;
}

/** BUTTONS **/
button, input[type="button"], input[type="submit"] {
  display: inline-block;
  height: 2.5em;
  line-height: 2.5em;
  padding: 0 1.25em;
  border-radius: 0;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 5px;
}

/** NAV-BAR **/
nav {
  background: #0070bd;
  margin-top: 1.88em;
  position: relative;
  z-index: 1;
  height: 3.75em;
}

nav ul {
  line-height: 3.76em;
  height: 3.75em;
  float: left;
  margin: 0;
  list-style: none;
  padding: 0;
}

nav li {
  display: inline-block;
  background: #0070bd;
  cursor: pointer;
  margin-right: -4px;
  text-align: center;
}

/** Needs ie8 class **/
.ie8 nav li {
  min-width: 140px;
}

nav li:hover {
  background: #0861a2;
  color: white;
}

nav ul li a:hover {
  color: white;
}

nav li a {
  color: white;
  height: 3.75em;
  padding: 0 1.687em;
  font-size: 16px;
  display: block;
}

/** Needs active class **/
nav li.active {
  background: #0861a2;
}