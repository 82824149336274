.top-navigation {

  .navbar {
    .navbar-header {
      background-color: white;
    }

    .navbar-collapse li a {
      padding-top: 25px;

      &:hover, &:focus {
        color: $navy;
      }
    }

    .navbar-collapse li.active {
      a {
        color: $navy;
        text-decoration: underline;
      }
    }
  }
}

.top-navigation .wrapper.wrapper-content {
  padding: 25px 0px 25px;
}

#nav-mt-corp-logo-container {
  background: transparent;
  background-color: rgba(255,255,255,0.01);
}

.logo-header + nav {
  margin: 0px;
}