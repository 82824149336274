table {
  table-layout: auto;

  th {
    text-align: center;
  }

  .text {
    text-align: left;
  }
  .numbers {
    text-align: center;
  }
  .icon {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }

  td {
    text-align: left;
  }
}

.table-engagement {
  height: 295px;
  max-height: 295px;
}