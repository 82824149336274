@font-face {
    font-family: 'ProximaNova';
    font-weight: normal;
    font-style: normal;
    src: url('/assets/webfonts/ProximaNova-Regular-Full.eot');
    src: url('/assets/webfonts/ProximaNova-Regular-Full.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/ProximaNova-Regular-Full.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-Regular-Full.woff') format('woff'), url('/assets/webfonts/ProximaNova-Regular-Full.ttf') format('truetype'), url('/assets/webfonts/ProximaNova-Regular-Full.svg#wf') format('svg');
    unicode-range: U+0100-2008, U+2027-2121, U+2123-FFFF;
}
@font-face {
    font-family: 'ProximaNova';
    font-weight: normal;
    font-style: normal;
    src: url('/assets/webfonts/ProximaNova-Regular.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-Regular.woff') format('woff');
    unicode-range: U+0000-00FF, U+2009-2026, U+2122;
}

@font-face {
    font-family: 'ProximaNova';
    font-weight: bold;
    font-style: normal;
    src: url('/assets/webfonts/ProximaNova-Bold.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-Bold.woff') format('woff');
    unicode-range: U+0000-00FF, U+2009-2026, U+2122;
}
@font-face {
    font-family: 'ProximaNova';
    font-weight: bold;
    font-style: normal;
    src: url('/assets/webfonts/ProximaNova-Bold-Full.eot');
    src: url('/assets/webfonts/ProximaNova-Bold-Full.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/ProximaNova-Bold-Full.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-Bold-Full.woff') format('woff'), url('/assets/webfonts/ProximaNova-Bold-Full.ttf') format('truetype'), url('/assets/webfonts/ProximaNova-Bold-Full.svg#wf') format('svg');
    unicode-range: U+0100-2008, U+2027-2121, U+2123-FFFF;
}

@font-face {
    font-family: 'ProximaNova';
    font-weight: normal;
    font-style: italic;
    src: url('/assets/webfonts/ProximaNova-RegularIt.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-RegularIt.woff') format('woff');
    unicode-range: U+0000-00FF, U+2009-2026, U+2122;
}
@font-face {
    font-family: 'ProximaNova';
    font-weight: normal;
    font-style: italic;
    src: url('/assets/webfonts/ProximaNova-RegularIt-Full.eot');
    src: url('/assets/webfonts/ProximaNova-RegularIt-Full.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/ProximaNova-RegularIt-Full.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-RegularIt-Full.woff') format('woff'), url('/assets/webfonts/ProximaNova-RegularIt-Full.ttf') format('truetype'), url('/assets/webfonts/ProximaNova-RegularIt-Full.svg#wf') format('svg');
    unicode-range: U+0100-2008, U+2027-2121, U+2123-FFFF;
}

@font-face {
    font-family: 'ProximaNova';
    font-weight: bold;
    font-style: italic;
    src: url('/assets/webfonts/ProximaNova-BoldIt.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-BoldIt.woff') format('woff');
    unicode-range: U+0000-00FF, U+2009-2026, U+2122;
}
@font-face {
    font-family: 'ProximaNova';
    font-weight: bold;
    font-style: italic;
    src: url('/assets/webfonts/ProximaNova-BoldIt-Full.eot');
    src: url('/assets/webfonts/ProximaNova-BoldIt-Full.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/ProximaNova-BoldIt-Full.woff2') format('woff2'), url('/assets/webfonts/ProximaNova-BoldIt-Full.woff') format('woff'), url('/assets/webfonts/ProximaNova-BoldIt-Full.ttf') format('truetype'), url('/assets/webfonts/ProximaNova-BoldIt-Full.svg#wf') format('svg');
    unicode-range: U+0100-2008, U+2027-2121, U+2123-FFFF;
}

body {
    font-family: $font-stack;
}

.ibox-title h5 {
    font-size: 18px;
}


